import React from 'react'
import Button from '../../atoms/button/'

const Pagination = props => {
    const { currentPage, numPages } = props.context
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1
        ? `/${props.path}`
        : `/${props.path}/` + (currentPage - 1).toString()
    const nextPage = `/${props.path}/` + (currentPage + 1).toString()
    console.log(numPages)

    return(
    <div className="m-pagination">
        {!isFirst && (
            <Button
                url={prevPage}
                iconLeft="fas fa-chevron-left"
                rel="prev"
                class="prev"
            />
        )}
        {Array.from({ length: numPages }, (_, i) => (
            <Button
                key={`pagination-number${i + 1}`}
                url={`/${props.path}/${i === 0 ? '' : i + 1}`}
                class={ currentPage === i + 1 ? 'current' : ''}
                title={i + 1}
            />
            ))}
        {!isLast && (
            <Button
                url={nextPage}
                iconRight="fas fa-chevron-right"
                rel="next"
                class="next"
            />
        )}
    </div>
)}

export default Pagination
