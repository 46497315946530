import React from 'react'
import Page from '../page/'
import Tags from '../../atoms/tags/'
import Search from '../../molecules/search/'
import Pagination from '../../molecules/pagination/'
import Keywords from '../../molecules/keywords/'
import ArticlesList from '../../organisms/article-list/'
import Button from '../../atoms/button/'

const Articles = props => {
  return (
    <Page meta={props.meta}>
        <div className="row">
          <div className="col-lg-8">
            <Search classNames={'t-articles'} cols="6" lang="en" />
          </div>
        </div>
        <div className="row t-articles">
          <div className="col-md-8 t-articles__content">
            { props.is_front ? (<h3>Latest articles</h3>) : (<h3>Articles</h3>) }
            <ArticlesList posts={props.posts}/>
            { !props.is_front && props.context.numPages > 1 ? (
              <Pagination path="articles" context={props.context} />
              ) : props.is_front ? (
              <Button
                url='/articles'
                iconRight="fas fa-chevron-right"
                title="see all"
                class="is_front"
              />
              ) : null }
          </div>
          <div className="col-md-4 t-articles__navigation">
            { props.is_front ? (
              <div className="t-articles__about">
                <div className="day">
                  <h3>Hello! My name is Tiago Santos.</h3>
                  <div className="caption">Designer and developer during the day</div>
                  <Tags tags={props.tags}/>
                </div>
                <div className="night">
                  <div className="caption">Complexity Sciences PhD student at night</div>
                  <Keywords />
                </div>
              </div>  
              ) : (
                <div>
                <h3>Tags</h3>
                <Tags tags={props.tags}/>
                </div>
              ) }
          </div>
        </div>
    </Page>
  )
}

export default Articles