import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Tags from '../../atoms/tags/'

const Keywords = () => {
  const posts = useStaticQuery(
    graphql`
        query {
            allMarkdownRemark {
                edges {
                    node {
                        frontmatter {
                        keywords
                        }
                    }
                }
            }
        }
  `)

    const keywords = []

    posts.allMarkdownRemark.edges.map(( {node} ) => {
    if (node.frontmatter.keywords) {
        node.frontmatter.keywords.map(keyword => {
        if (keywords.indexOf(keyword) === -1) {
            keywords.push(keyword)
        }
    })
    }
    })
 
  return (
    <Tags tags={keywords}/>
  )
  }

export default Keywords