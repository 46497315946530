import React from 'react'
import { graphql } from 'gatsby'
import Articles from '../components/templates/articles/'

const FrontPage = props => {
  const posts = props.data.allMarkdownRemark
  const metaData = props.data.site.siteMetadata
  const tags = []

  const published = []
  posts.edges.map(({ node }) => ( published.push(node)))

  published.map(( node ) => (
    node.frontmatter.tags.map(tag => {
      if (tags.indexOf(tag) === -1) {
        tags.push(tag)
      }
  })))

  const meta = {
    title: metaData.title,
    description: metaData.description,
    pathname: "/"
  }

  return (
    <Articles posts={published.slice(0, 3)} context={props.pageContext} tags={tags} is_front={true} meta={meta} />
  )
}

export default FrontPage

export const FrontPageQuery = graphql`
  query FrontPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    },
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: {frontmatter: {publish: {ne: false}}}
    ) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
            tags
            url
          }
        }
      }
    }
  }
`
